html,
body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  background-color: rgb(56, 56, 56);
  color: #fcfcf4;
  overflow: hidden;
}

html,
body,
button,
input {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

#app {
  height: 100%;
  width: 100%;
  display: flex;
}

#map {
  flex: 1 0 auto;
  display: flex;
}

#tools {
  flex: 0 0 200px;
  margin: 6px;
  display: flex;
  flex-direction: column;
}

#tools .list {
  flex: 1;
  padding-left: 1rem;
  overflow-y: auto;
  margin: 0;
}

#tools .list li {
  position: relative;
  padding-bottom: 30px;
  cursor: pointer;
}

#tools .list .distance {
  text-align: center;
  border: solid 1px rgba(255, 255, 255, .4);
  border-radius: 8px;
  padding: 4px;
  font-size: small;
  position: relative;
  left: -12px;
  top: 14px;
}

#tools .list .remove {
  background: url(../images/trash-16.png) no-repeat 0 50%;
  width: 16px;
  opacity: .4;
}

#tools .list li:last-child::before {
  content: none;
}

#tools .list li::before {
  content: "";
  position: absolute;
  border-left: solid 1px rgba(255, 255, 255, .4);
  left: -12px;
  bottom: 0;
  height: 70%;
  width: 1px;
}

#tools a,
#tools a:active,
#tools a:visited {
  text-decoration: none;
  color: white;
}

.logo {
  background-image: url(../images/komoot-logo.svg);
  background-size: 130px 32px;
  background-position: 0% 50%;
  background-repeat: no-repeat;
  height: 32px;
  padding-left: 125px;
  font-weight: bold;
  color: rgb(105, 105, 101);
  margin-bottom: 1rem;
}

#tools .version {
  font-size: xx-small;
  text-align: right;
}

input[type=button],
input[type=reset],
input[type=submit],
button {
  border: 0;
  background-color: rgb(195, 228, 82);
  border-radius: 4px;
  padding: 6px 16px;
  outline: 0;
  cursor: pointer;
}

input[type=button]:hover,
input[type=reset]:hover,
input[type=submit]:hover,
button:hover {
  opacity: .8;
}

input[type=button]:active,
input[type=reset]:active,
input[type=submit]:active,
button:active {
  opacity: .4;
}

#tools button {
  display: block;
  margin-bottom: 12px;
  width: 100%;
}

#tools button.secondary {
  border: 1px solid rgb(105, 105, 101);
  background-color: transparent;
  color: rgb(195, 228, 82);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .5);
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  outline: 0;
  justify-content: center;
  align-items: center;
}

.modal-content {
  z-index: 1050;
  background-color: rgb(56, 56, 56);
  position: relative;
  max-width: 500px;
  padding: 0 1.5rem 1.5rem;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
  margin: 0 -1.5rem;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.modal-buttons *:only-child {
  align-self: flex-end;
  margin-left: auto;
}

button.modal-close {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  opacity: .3;
  cursor: pointer;
  border: none;
}

form > input,
form > label {
  display: block;
}

form > label {
  margin-top: 1rem;
}

.hint {
  background-color: rgba(0, 0, 0, .2);
  font-size: x-small;
  padding: .25rem;
  text-align: center;
}

a.feedback {
  transition: all .2s ease-in-out;
}

a.feedback:hover {
  transform: scale(4);
  z-index: 10000; /* Don't clip that happy face by the map */
}

a.negative.feedback:hover {
  animation: shake .82s cubic-bezier(.36, .07, .19, .97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@media (max-width: 639px) {
  #app {
    flex-direction: column-reverse;
  }

  #tools {
    flex-basis: auto;
  }

  #tools .logo {
    transform: scale(.5);
    position: absolute;
    bottom: -4px;
    margin: 0;
    transform-origin: left;
  }

  #tools .track-infos .hint,
  #tools .track-infos .total-distance {
    display: none;
  }

  #tools .export {
    display: flex;
  }

  #tools .export > button:not(:last-child) {
    margin-right: 12px;
  }

  #tools .list {
    display: flex;
    flex: 1 0 auto;
    margin: 0 0 1rem;
    padding: 0;
    overflow-x: auto;
    overflow-y: hidden;
  }

  #tools .list li {
    padding: 0;
    margin: 0 15px;
    flex: 1 0 auto;
    flex-direction: row-reverse;
    max-width: 100px;
    display: flex;
    font-size: smaller;
    justify-content: space-between;
  }

  #tools .list li > div {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    white-space: pre;
    flex-shrink: 0;
  }

  #tools .list .distance {
    padding-top: 2px;
    padding-bottom: 2px;
    left: 40px;
    top: 0;
  }

  #tools .list li::before {
    border-top: solid 1px rgba(255, 255, 255, .4);
    left: 10px;
    bottom: 45px;
    height: 1px;
    width: 29px;
  }

  #tools .list li:first-child::before {
    content: none;
  }

  #tools .list li:last-child::before {
    content: "";
  }
}

#map .marker {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  color: white;
  font-weight: 800;
  text-shadow: 1px 1px #000;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 12px -1px;
  background: black;
  padding: 6px;
}
